import { useState } from 'react'
import './index.scss'
import OrderModal from '../../order-modal'
import InfoModal from '../../info-modal'
import { useNavigate } from 'react-router-dom'

const OrderButton = ({ order, disabled }) => {
  const [orderModalOpen, setOrderModalOpen] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [error, setError] = useState(false)
  const [text, setText] = useState('')
  const navigate = useNavigate()

  const handleOnSuccess = () => {
    setOrderModalOpen(false)
    setError(false)
    navigate('/success', {
      state: {
        type: 'order'
      }
    })
  }

  const handleOnError = () => {
    setOrderModalOpen(false)
    setInfoModalOpen(true)
    setError(true)
    setText('Виникла помилка. Спробуйте ще раз пізніше, або зв\'яжіться з нами за телефоном.')
  }

  return (
    <div>
      <button
        className={`button-main order-button ${disabled ? 'disabled' : ''}`}
        onClick={() => setOrderModalOpen(true)}
      >
        Замовити
      </button>
      <OrderModal
        open={orderModalOpen}
        onClose={() => setOrderModalOpen(false)}
        order={{
          product: order?.productItem?.title,
          specification: order?.selectedProduct?.text || '',
          count: order?.count,
          price: order?.price
        }}
        onSuccess={handleOnSuccess}
        onError={handleOnError}
      />
      <InfoModal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        text={text}
        error={error}
      />
    </div>
  )
}

export default OrderButton