import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import ProductCount from '../product-count'
import OrderButton from '../order-button'
import Description from '../description'

const ProductThree = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)

  return (
    <div className="product-info">
      <h3 className="title">
        {productItem.title}
      </h3>
      <ProductCount
        price={productItem?.price}
        count={count}
        setCount={setCount}
      />
      <OrderButton order={{ count, price: productItem?.price, productItem }} />
      <Description>
        <p>
          <span>Тип:</span>
          <span>Li-ion</span>
        </p>
        <p>
          <span>Ємність:</span>
          <span>4200 mAh</span>
        </p>
        <p>
          <span>Струм розряду:</span>
          <span>45A</span>
        </p>
        <p>
          <span>Робоча напруга:</span>
          <span>20,4 – 25,2V</span>
        </p>
        <p>
          <span>Вага:</span>
          <span>475 (/- 5) гр.</span>
        </p>
        <p>
          <span>Габарити корпусу (д. ш. в.)</span>
          <span>145 / 24 / 65 (/-2мм)</span>
        </p>
      </Description>
    </div>
  )
}

export default ProductThree