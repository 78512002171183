import './index.scss'
import { HashLink as Link } from 'react-router-hash-link'
import close from './img/close.svg'
import plus from './img/plus.svg'

const Faq = ({ smDevice }) => {
  const handleClick = (e) => {
    const parent = e.target.closest('.faq__list-item')
    const classList = parent.classList
    if (classList.contains('open')) {
      classList.remove('open')
    } else {
      classList.add('open')
    }
  }

  return (
    <section className="faq" id="faq">
      <div className="container">
        <div className="faq__content">
          <div className="faq__content-left">
            <h3 className="title">Часті запитання</h3>
            {!smDevice && (
              <div>
                <img
                  className="faq__img"
                  src={require(`./img/support.png`)}
                  srcSet={`${require(`./img/support.png`)} 2x`}
                />
                <p className="faq__text faq__text--left">
                  Не знайшли відповіді?
                </p>
                <Link to="#contact-form" className="button-outlined button-outlined--small">
                  Написати нам
                </Link>
              </div>
            )}
          </div>
          <div className="faq__list">
            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">01.</div>
                <h4 className="faq__list-item-title">
                  Які характеристики Акумуляторної батареї SPAS.Energy для БпЛА Li-ion 6S2P 8400mAh 90А:
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Тип: Li-ion</p>
                <p>Робоча напруга: 20,4 – 25,2V</p>
                <p>Формфактор: 6S2P</p>
                <p>Ємність: 8400 mAh</p>
                <p>Струм розряду: 90A</p>
                <p>Вага: 880 (+/- 10) гр.</p>
                <p>Розмір батареї : 65х43х155 мм.</p>
                <p>Зварювання на міді</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">02.</div>
                <h4 className="faq__list-item-title">
                  На яких елементах зібрана акумуляторна батарея SPAS Energy для БпЛА Li-ion 6S2P 8400mAh 90А?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Високострумовий аккумулятор MOLICEL INR21700P42A-V3</p>
                <p>Робоча напруга: 3,4 – 4,2 V</p>
                <p>Ємність: 4200 mah</p>
                <p>Струм розряду: 45A</p>
                <p>Типорозмір: 21700</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">03.</div>
                <h4 className="faq__list-item-title">
                  З якими дронами використовуються акумуляторні батареї SPAS Energy для БпЛА Li-ion 6S2P 8400mAh 90А?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Батарея SPAS Energy 6S2P сумісна з більшістю дронів, які потребують акумуляторні Li-іon батареї з
                  напругою 20.4 – 25.2 V.</p>
                <p>Висока якість збірки акумуляторних батарей дозволяє гарантовано застосовувати їх як в
                  дронах-камікадзе так і в дронах-бомберах.</p>
                <p className="underline">Виробник може по замовленню замовника виробити акумуляторні батареї з
                  заявленими характеристиками.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">04.</div>
                <h4 className="faq__list-item-title">
                  Як правильно заряджати та зберігати акумуляторні батареї SPAS Energy для БпЛА Li-ion 6S2P 8400mAh 90А?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p className="underline">Зарядка:</p>
                <p>a. Використовуйте лише якісні зарядні пристрої, призначені для Li-іon акумуляторів, з функцією
                  балансування.</p>
                <p>b. Бажано заряджати батарею при температурі від 0°C до 45°C оточуючого середовища. В іншому випадку
                  строк служби батареї буде коротше заявленого.</p>
                <p>c. Рекомендується заряджати батарею до напруги 25,2V.</p>
                <p className="underline">Зберігання:</p>
                <p>a. Запобігайте ударів та механічних пошкоджень корпусу та кабелю акумуляторної батареї. При
                  пошкодженні батарея може загорітись.</p>
                <p>b. Не зберігайте батарею поряд з боєкомплектом та/чи легкозаймистими речовинами. Бажано зберігати
                  батарею в сухому і прохолодному місці.</p>
                <p>c. Не зберігайте батарею при температурі нижче -20°C або вище 60°C.</p>
                <p>d. Зберігайте батарею частково зарядженою (21,9V-22,2V). Це важливо для збереження батареєю її
                  максимальних експлуатаційних якостей.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">05.</div>
                <h4 className="faq__list-item-title">
                  Який термін служби акумуляторних батарей SPAS Energy для БпЛА Li-ion 6S2P 8400mAh 90А?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Термін служби батарейок SPAS.Energy становить 6 місяців.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">06.</div>
                <h4 className="faq__list-item-title">
                  Яка гарантія на акумуляторні батареї SPAS Energy для БпЛА Li-ion 6S2P 8400mAh 90А?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Акумуляторні батареї SPAS.Energy мають гарантію 6 місяців або 100 циклів заряд-розряд струмом вище 30
                  Ампер та 500 циклів струмом 10 Ампер.</p>
                <p className="bold">Гарантія покриває:</p>
                <ul>
                  <li>Виробничі дефекти</li>
                  <li>Роботу акумулятора з відхиленням від заявлених показників.</li>
                </ul>
                <p className="bold">Гарантія не покриває:</p>
                <ul>
                  <li>Випадки фізичного пошкодження</li>
                  <li>Втрату ємності акумулятора внаслідок звичайного зносу.</li>
                </ul>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">07.</div>
                <h4 className="faq__list-item-title">
                  Процедура обміну
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Покупець повинен звернутись до виробника за інструкціями щодо обміну. Для обміну вимагається
                  перевірка стану акумулятора виробником.</p>
                <p className="bold">Умови повернення:</p>
                <ul>
                  <li>Покупець повинен мати квитанцію чи інший документ, що підтверджує покупку, як доказ права на
                    гарантійне обслуговування.
                  </li>
                  <li>Якщо акумулятор не відповідає гарантійним умовам обміну, акумуляторна батарея може бути
                    відремонтована чи змінена за рахунок покупця.
                  </li>
                </ul>
                <p className="bold">Доставка:</p>
                <ul>
                  <li>Доставка здійснюється Новою поштою чи перевізником покупця.</li>
                </ul>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">08.</div>
                <h4 className="faq__list-item-title">
                  Які послуги зі збірки пропонує SPAS.Energy?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>SPAS.Energy пропонує збірку акумуляторних батарей з комплектуючих замовника по технічному завданню
                  замовника.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">09.</div>
                <h4 className="faq__list-item-title">
                  Скільки коштують послуги зі збірки SPAS.Energy?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Вартість послуги зі збірки залежить від складності технічного завдання.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">10.</div>
                <h4 className="faq__list-item-title">
                  Які терміни виконання послуги зі збірки SPAS.Energy?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Термін виконання послуги зі збірки залежить від обсягу замовлення, конфігурації батареї та кількості
                  замовлень в роботі. В цілому – продуктивність на цій послузі становить коло 60 шт/день.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">11.</div>
                <h4 className="faq__list-item-title">
                  Чи можу я замовити збірку акумуляторної батареї для мого конкретного дрона?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Так, SPAS.Energy може здійснити збірку акумуляторної батареї для вашого конкретного дрона, якщо ви
                  надасте відповідні технічні характеристики.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">12.</div>
                <h4 className="faq__list-item-title">
                  Чи можу я використовувати батареї SPAS.Energy в інших пристроях, окрім дронів?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Так, батареї SPAS.Energy можуть використовуватись в будь-яких пристроях з робочою напругою від 20,4
                  до 25,2 Вольт, з підключенням по XT60 та піковим струмом до 90 А. </p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">13.</div>
                <h4 className="faq__list-item-title">
                  Як замовити акумуляторні батареї SPAS Energy?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Замовити батарейки SPAS.Energy можна на нашому сайті, перейшовши до розділу “Замовити”.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">14.</div>
                <h4 className="faq__list-item-title">
                  Які способи оплати доступні для замовлення акумуляторних батарей SPAS Energy?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Можливі такі варіанти:</p>
                <p>100% передплата на рахунок продавця.</p>
                <p>Оплата накладеним платежем при відправленні Новою поштою.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">15.</div>
                <h4 className="faq__list-item-title">
                  Які способи та терміни доставки акумуляторних батарей SPAS Energy?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Доставка здійснюється Новою поштою або перевізником покупця за рахунок покупця. Термін доставки
                  становить 1-3 дні.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">16.</div>
                <h4 className="faq__list-item-title">
                  Чи можу я замовити акумуляторні батареї SPAS Energy з доставкою в інше місто?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Так, ми можемо доставити батарейки SPAS Energy в будь-яке місто України де працює відділення або є
                  можливість доставки Новою поштою, крім тимчасово окупованих територій.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">17.</div>
                <h4 className="faq__list-item-title">
                  Як я можу залишити відгук про акумуляторні батареї SPAS Energy?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Ви можете залишити відгук про акумуляторні батареї SPAS Energy на нашому сайті в розділі “Контакти”.
                  Перейдіть до розділу і напишіть свій відгук, заповнивши форму.</p>
              </div>
            </div>

            <div className="faq__list-item">
              <div onClick={handleClick}>
                <div className="faq__list-item-number">18.</div>
                <h4 className="faq__list-item-title">
                  Як я можу запропонувати покращення для акумуляторних батарей SPAS Energy?
                </h4>
                <div className="faq__list-item-btn">
                  <img className="faq__list-item-plus" src={plus} alt="Plus" />
                  <img className="faq__list-item-close" src={close} alt="close" />
                </div>
              </div>
              <div>
                <p>Ви можете запропонувати покращення акумуляторних батарей SPAS Energy на нашому сайті в розділі
                  “Контакти” так само як і залишити відгук. Перейдіть до розділу і вкажіть опис своєї пропозиції,
                  заповнивши форму.</p>
                <p className="bold">Важливо:</p>
                <ul>
                  <li>Ми цінуємо ваші відгуки та пропозиції, адже вони допомагають нам покращувати нашу продукцію.</li>
                  <li>Будь ласка, пишіть свої відгуки та пропозиції чітко та лаконічно.</li>
                  <li>Ми не можемо гарантувати, що всі відгуки та пропозиції будуть втілені в життя, але ми обов'язково
                    їх розглянемо.
                  </li>
                </ul>
                <p className="bold">Дякуємо за вашу допомогу!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq