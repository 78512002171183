import './index.scss'

const About = () => {
  const arr = [
    {
      title: 'Дальність польоту',
      text: 'Вища ємність батареї забезпечує більшу дистанцію польоту для БпЛА.'
    },
    {
      title: 'Передбачуваність',
      text: 'Батареї завжди тримаються заявленої ємності, що забезпечує точне планування операцій.'
    },
    {
      title: 'Зносостійкість',
      text: 'Ці батареї забезпечують стабільну ємність протягом багатьох циклів заряду-розряду.'
    },
    {
      title: 'Корисне навантаження',
      text: 'Максимальна ємність при мінімальній вазі забезпечує ефективне використання для різних навантажень на БпЛА.'
    }
  ]
  return (
    <section className="about" id="about">
      <div className="container">
        <h3 className="title">Наші переваги</h3>
        <p className="about__text">
          Контроль якості на всіх етапах виробництва гарантує надійність. Прогнозована ємність та потужність струму спрощують планування місій, а відповідність військовим стандартам забезпечує надійність у будь-яких умовах.
        </p>

        <div className="about__list">
          {arr.map((item, index) => {
            return (
              <div
                className="about__list-item"
                key={index}
              >
                <div className="about__list-item-number">0{index + 1}</div>
                <h4 className="about__list-item-title">{item.title}</h4>
                <div className="about__list-item-divider" />
                <p className="about__list-item-text">{item.text}</p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default About