import Header from './components/header'
import Products from './components/products'
import About from './components/about'
import Application from './components/application'
import Faq from './components/faq'
import Contacts from './components/contacts'
import {
  useEffect,
  useState
} from 'react'

const App = () => {
  const [smDevice, setSmDevice] = useState(
    window.matchMedia('(max-width: 768px)').matches
  )
  const [mdDevice, setMdDevice] = useState(
    window.matchMedia('(max-width: 960px)').matches
  )

  useEffect(() => {
    window
      .matchMedia('(max-width: 768px)')
      .addEventListener('change', e => setSmDevice(e.matches))
    window
      .matchMedia('(max-width: 960px)')
      .addEventListener('change', e => setMdDevice(e.matches))
  }, [])

  return (
    <div className="App">
      <Header smDevice={smDevice} mdDevice={mdDevice} />
      <Products />
      <About />
      {/*<Application smDevice={smDevice} />*/}
      <Faq smDevice={smDevice} />
      <Contacts smDevice={smDevice} />
    </div>
  )
}

export default App
