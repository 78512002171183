import './index.scss'
import signal from './img/signal.svg'
import whatsapp from './img/whatsapp.svg'
import telegram from './img/telegram.svg'
import viber from './img/viber.svg'
import instagram from './img/instagram.svg'
import fb from './img/fb.svg'

const SocialButtons = () => {
  return (
    <div className="social-buttons">
      <a href="https://signal.me/#p/+380955957277" target="_blank" className="social-buttons__button">
        <img src={signal} alt="Signal" />
      </a>
      <a href="https://wa.me/380955957277?text=I%27m%20interested%20in%20drone%20battery" target="_blank" className="social-buttons__button">
        <img src={whatsapp} alt="WhatsApp" />
      </a>
      <a href="https://t.me/+380955957277" target="_blank" className="social-buttons__button">
        <img src={telegram} alt="Telegram" />
      </a>
      <a href="https://msng.link/o?380955957277=vi" target="_blank" className="social-buttons__button">
        <img src={viber} alt="Viber" />
      </a>
      <a href="https://www.instagram.com/spas.energy/" target="_blank" className="social-buttons__button">
        <img src={instagram} alt="Instagram" />
      </a>
      <a href="https://www.facebook.com/spas.energy" target="_blank" className="social-buttons__button">
        <img src={fb} alt="Facebook" />
      </a>
    </div>
  )
}

export default SocialButtons