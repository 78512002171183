import './index.scss'
import Slider from 'react-slick'
import productItems from '../products/productsItems'

const SampleNextArrow = ({ onClick }) => {
  return (
    <div
      className="product-card__arrow product-card__arrow--next"
      onClick={onClick}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 6L15 12L9 18" stroke="#292929" strokeWidth="2" />
      </svg>
    </div>
  )
}

const SamplePrevArrow = ({ onClick }) => {
  return (
    <div
      className="product-card__arrow product-card__arrow--prev"
      onClick={onClick}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 6L9 12L15 18" stroke="#292929" strokeWidth="2" />
      </svg>
    </div>
  )
}

const ProductCard = ({ productId }) => {
  const images = [1, 2, 3, 4]
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const settings = {
    customPaging: function(i) {
      const num = i + 1
      return (
        <a>
        <img
            src={require(`../../img/products/product-${productId}/img-${num}.jpeg`)}
            srcSet={`${require(`../../img/products/product-${productId}/img-${num}@2x.jpeg`)} 2x`}
          />
        </a>
      )
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          customPaging: i => <button>{i + 1}</button>,
          dotsClass: 'slick-dots'
        }
      }
    ]
  }

  return (
    <section className="product-card">
      <div>
        {/*<Slider*/}
        {/*  {...settings}*/}
        {/*>*/}
        {/*  {images.map((item, index) => {*/}
        {/*    return (*/}
        {/*      <div key={index} className="product-card__slide">*/}
        {/*        <img*/}
        {/*          src={require(`../../img/products/product-${productId}/img-${item}.jpeg`)}*/}
        {/*          srcSet={`${require(`../../img/products/product-${productId}/img-${item}@2x.jpeg`)} 2x`}*/}
        {/*        />*/}
        {/*        <div className="product-card__slide-num">*/}
        {/*          {item}/<span>{images.length}</span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    )*/}
        {/*  })}*/}
        {/*</Slider>*/}
        <div className="product-card__slide">
          <img
            src={require(`../../img/products/product-${productId}/img-1.jpeg`)}
            srcSet={`${require(`../../img/products/product-${productId}/img-1@2x.jpeg`)} 2x`}
          />
        </div>
      </div>
      {productItem.component}
    </section>
  )
}

export default ProductCard