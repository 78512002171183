import './index.scss'
import logo from '../../img/logo-white.svg'
import email from '../social-buttons/img/email.svg'
import signal from '../social-buttons/img/signal.svg'
import whatsapp from '../social-buttons/img/whatsapp.svg'
import telegram from '../social-buttons/img/telegram.svg'
import viber from '../social-buttons/img/viber.svg'
import phone from '../social-buttons/img/phone.svg'
import instagram from '../social-buttons/img/instagram.svg'
import fb from '../social-buttons/img/fb.svg'
import { Link } from 'react-router-dom'
import Form from '../form'
import InfoModal from '../info-modal'
import { useState } from 'react'

const Contacts = ({ smDevice }) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [error, setError] = useState(false)
  const [text, setText] = useState('')

  const handleOnSuccess = () => {
    setInfoModalOpen(true)
    setError(false)
    setText('Дякуємо за повідомлення! Наш менеджер зв\'яжеться з вами найближчим часом.')
  }

  const handleOnError = () => {
    setInfoModalOpen(true)
    setError(true)
    setText('Виникла помилка. Спробуйте ще раз пізніше, або зв\'яжіться з нами за телефоном.')
  }

  return (
    <footer className="contacts" id="contacts">
      <InfoModal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        text={text}
        error={error}
      />
      <div className="container">
        <div className="contacts__top">
          <div id="contact-form">
            <h3 className="title title--light">Написати нам</h3>
            <p className="contacts__text">
              З питань співпраці, замовлень або якщо у вас виникли запитання, звертайтеся сюди.
            </p>
            <Form
              onSuccess={handleOnSuccess}
              onError={handleOnError}
            />
          </div>
          {!smDevice && (
            <div>
              <img
                className="contacts__img"
                src={require('./img/contacts-img.png')}
                srcSet={`${require('./img/contacts-img@2x.png')} 2x`}
              />
            </div>
          )}
        </div>
      </div>
      <div className="container contacts__container">
        <div className="contacts__middle">
          <div>
            <Link to="/">
              <img
                className="contacts__logo"
                alt="Logo"
                src={logo}
              />
            </Link>
            <p className="contacts__text contacts__text--middle">
              Український виробник акамуляторних батарей для БПЛА
            </p>
          </div>
          <div className="contacts__socials">
            <div>
              <h4 className="contacts__block-title">Контакти</h4>
              <a href="mailto:info@spas.energy" className="contacts__social-link">
                <img src={email} alt="Email" />
                <p>info@spas.energy</p>
              </a>
              <a href="tel:380955957277" className="contacts__social-link">
                <img src={phone} alt="Phone" />
                <p>+380955957277</p>
              </a>
            </div>
            <div>
              <h4 className="contacts__block-title">Соц. мережі</h4>
              <a href="https://signal.me/#p/+380955957277" className="contacts__social-link">
                <img src={signal} alt="Signal" />
                <p>Signal</p>
              </a>
              <a href="https://wa.me/380955957277?text=I%27m%20interested%20in%20drone%20battery" className="contacts__social-link">
                <img src={whatsapp} alt="WhatsApp" />
                <p>WhatsApp</p>
              </a>
              <a href="https://t.me/+380955957277" className="contacts__social-link">
                <img src={telegram} alt="Telegram" />
                <p>Telegram</p>
              </a>
              <a href="https://msng.link/o?380955957277=vi" className="contacts__social-link">
                <img src={viber} alt="Viber" />
                <p>Viber</p>
              </a>
              <a href="https://www.instagram.com/spas.energy/" className="contacts__social-link">
                <img src={instagram} alt="Instagram" />
                <p>Instagram</p>
              </a>
              <a href="https://www.facebook.com/spas.energy" className="contacts__social-link">
                <img src={fb} alt="Facebook" />
                <p>Facebook</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contacts__bottom">
          <p className="contacts__text contacts__text--bottom">
            &copy; Всі права захищені SPAS Energy {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Contacts