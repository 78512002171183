import './index.scss'
import {
  useEffect,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const SuccessPage = () => {
  const { pathname, state } = useLocation()
  const navigate = useNavigate()
  const type = state?.type

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <main className="success-page">
      <div className="container">
        <div className="success-page__content">
          <h3>
            Дякуємо за замовлення!
          </h3>
          <p>
            Наш менеджер зв'яжеться з вами найближчим часом.
          </p>
          <button className="button-outlined" onClick={() => navigate('/')}>
            На головну
          </button>
        </div>
      </div>
    </main>
  )
}

export default SuccessPage