import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
// import ProductCount from '../product-count'
// import SpecificationSelect from '../specification-select'
import OrderButton from '../order-button'
import Description from '../description'

const ProductFour = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState({})

  return (
    <div className="product-info">
      <h3 className="title">
        {productItem.title}
      </h3>
      {/*<ProductCount*/}
      {/*  price={selectedProduct?.price}*/}
      {/*  count={count}*/}
      {/*  setCount={setCount}*/}
      {/*/>*/}
      {/*<SpecificationSelect*/}
      {/*  label="Оберіть комплектацію"*/}
      {/*  items={[*/}
      {/*    { text: 'Без тепловізору', price: 15000 },*/}
      {/*    { text: 'Victor 328B', price: 24000 },*/}
      {/*    { text: 'XH09', price: 30000 }*/}
      {/*  ]}*/}
      {/*  onChange={setSelectedProduct}*/}
      {/*  selectedProduct={selectedProduct}*/}
      {/*/>*/}
      <OrderButton
        order={{ count, price: selectedProduct?.price, productItem, selectedProduct }}
      />
      <Description>
        <p>
          Замовлення згідно з вашим технічним завданням. Розміщення елементів: горизонтальне або вертикальне.
        </p>
      </Description>
    </div>
  )
}

export default ProductFour